import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DigitalAnalyticsHeader from "components/Headers/DigitalAnalyticsHeader";
import NavBarSh from "components/Navbars/NavBarSh";
import { Card } from "@material-ui/core";
import CardText from "reactstrap/lib/CardText";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <NavBarSh />
      <div className="wrapper">
      <DigitalAnalyticsHeader />
      
        <div className="section section-strategy">
          <Container>
          <Col fluid className= "title text-center">
                 {/* <h3>
                  Unlocking the Power of Digital Analytics for Ecommerce Success
                  </h3> */}
                  <p>
                  The use of digital analytics strategies on ecommerce websites is becoming increasingly important. With so much competition in the online marketplace, 
                  it is important to understand how customers are interacting with your website and how you can use that data to improve your business. 
                  By using digital analytics, businesses can gain insight into customer behaviour, track conversions, and optimize their online presence. 
                  </p>
                  <p>
                  Digital analytics can be used to track customer journey, from the initial viewing of a product to the final purchase. 
                  This data can help businesses understand which products and services are most popular, identify any potential problems with the website, 
                  and understand where customers are dropping off in their purchasing journeys. 
                  By using digital analytics, businesses can also develop strategies to improve user experience, 
                  such as improving page load times or simplifying navigation.
                  </p>
                  <p>
                  In addition, digital analytics can provide valuable insight into how customers are engaging with your website, 
                  such as how often they visit, how long they stay, and what they're looking for. 
                  This data can help businesses create more personalized experiences and tailor their services to their customers. 
                  It can also help identify areas with potential to increase conversions and ROI.
                  </p>
                  <p>
                  By taking advantage of the power of digital analytics, businesses can gain an edge in the competitive ecommerce landscape.
                  </p>
                  
                </Col>
            <Row>
              <Col fluid>
                <h3 className="title text-center">Fully exploiting digital analytics requires three capabilities: </h3>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">Choose the right data.</CardTitle>
                         <CardText>Companies must be able to identify, combine, and manage multiple sources of data. </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">Use data to predict and optimize business outcomes. </CardTitle>
                         <CardText>They need the capability to build advanced-analytics models for predicting and optimizing outcomes. </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">The will to change </CardTitle>
                         <CardText>Management must possess the muscle to transform the organization so that the data and models actually yield better decisions. </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6  ">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/clients/bolcom.jpeg") + ")",
                    }}
                  >
                  </div>  
                </Col>
                <Col md="6">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/clients/abn.jpeg") + ")",
                    }}
                  ></div>
                  </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
