import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Button,
} from "reactstrap";

// core components

function Contact() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg12.jpg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-login card-plain">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                    <img alt="stefan-hoogers-digital-analytics" style={{borderRadius: '50%',opacity: '80%'}}
                          src={require("assets/img/profielfoto_sh.jpg")}
                       >
                      </img>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <h4>Please feel free to contact me at stefan@hoogersdigital.nl</h4>
                  </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Contact;
