import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import TabsSh from "views/index-sections/TabsSh.js";

// core components
//import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

function Home () {
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []); 
  return (
    <>
      <div className="wrapper">
        <ProfilePageHeader />
      <div className="section" >
          <Container>
            <div className="button-container">
              <Button
                className="btn-round btn-icon"
                color="default"
                id="tooltip515203352"
                size="lg"
                href="https://linkedin.com/in/stefanhoogers"
              >
                <i className="fab fa-linkedin-in"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip515203352">
                Connect on LinkedIn
              </UncontrolledTooltip>
            </div> 
            <h3 className="title">About me</h3>
            <h5 className="description">
            As a Digital Business Consultant I have over 10 years of experience working with national and international A-brands. 
            My passion for product management, big data, technology and consumer behaviour fuels me to constantly stay ahead of the curve.
            <br></br><br></br>
            I strive to stay informed of the latest innovations and opportunities in the digital world, so I can provide cutting-edge solutions to my clients. 
            My expertise in these areas gives me the ability to analyze data accurately and provide actionable insights.
            </h5>
            {/*<TabsSh></TabsSh>*/}
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        id="clients_icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        <i className="now-ui-icons emoticons_satisfied">
                            <UncontrolledTooltip delay={0} target="clients_icon">
                             Clients I've worked for
                            </UncontrolledTooltip></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        id="tools_icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <i className="now-ui-icons ui-2_settings-90"></i>
                        <UncontrolledTooltip delay={0} target="tools_icon">
                             Tools I work with
                            </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <TabContent className="gallery" activeTab={"pills" + pills}>
                <TabPane tabId="pills1">
                <h2 className="title text-center">Clients I've worked for:</h2>
                  <Col className="ml-auto mr-auto" fluid>
                    <Row className="collections">
                      <Col>
                        <img
                          alt="bol.com-logo"
                          className="img"
                          src={require("assets/img/clients/bolcom.jpeg")}
                        ></img>
                        <img
                          alt="argenta-logo"
                          className="img"
                          src={require("assets/img/clients/ar.jpg")}
                        ></img>
                        <img
                          alt="tribal-logo"
                          className="img"
                          src={require("assets/img/clients/tr.png")}
                        ></img>
                      </Col>
                      <Col>
                      <img
                          alt="abnamro-logo"
                          className="img"
                          src={require("assets/img/clients/abn.jpeg")}
                        ></img>
                        <img
                          alt="office-depot-logo"
                          className="img"
                          src={require("assets/img/clients/od.jpg")}
                        ></img>
                        <img
                          alt="kramp-logo"
                          className="img"
                          src={require("assets/img/clients/kr.jpg")}
                        ></img>
                      </Col>
                    <Col>
                          <img
                          alt="adidas-logo"
                          className="img"
                          src={require("assets/img/clients/ad.jpg")}
                        ></img>
                        <img
                          alt="sanoma-logo"
                          className="img"
                          src={require("assets/img/clients/sa.png")}
                        ></img>
                        <img
                          alt="iProspect-logo"
                          className="img"
                          src={require("assets/img/clients/ip.jpg")}
                        ></img>
                      </Col>
                      <Col>
                      <img
                          alt="ring-logo"
                          className="img"
                          src={require("assets/img/clients/ring.png")}
                        ></img>
                         <img
                          alt="centraal-beheer-logo"
                          className="img"
                          src={require("assets/img/clients/cb.jpg")}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="pills2">
                  <Col className="ml-auto mr-auto" fluid>
                  <h2 className="title text-center">Tools I work with:</h2>
                    <Row className="collections">
                      <Col fluid>
                        <img
                          alt="google-analytics-logo"
                          className="img"
                          src={require("assets/img/tools/ga.png")}
                        ></img>
                        <img
                          alt="adobe-analytics-logo"
                          className="img"
                          src={require("assets/img/tools/ado2.png")}
                        ></img>
                      </Col>
                      <Col fluid>
                        <img
                          alt="sql-logo"
                          className="img"
                          src={require("assets/img/tools/SQL2.png")}
                        ></img>
                        <img
                          alt="usabilla-logo"
                          className="img"
                          src={require("assets/img/tools/pbi-sq.png")}
                        ></img>
                      </Col>
                      <Col fluid>
                        <img
                          alt="sql-logo"
                          className="img"
                          src={require("assets/img/tools/pyth-bl.png")}
                        ></img>
                        <img
                          alt="r-logo"
                          className="img"
                          src={require("assets/img/tools/r.png")}
                        ></img>
                      </Col>
                      <Col fluid>
                        <img
                          alt="decibel-insights-logo"
                          className="img"
                          src={require("assets/img/tools/dec.png")}
                        ></img>
                        <img
                          alt="usabilla-logo"
                          className="img"
                          src={require("assets/img/tools/usab.png")}
                        ></img>
                      </Col>
                      <Col fluid>
                        <img
                          alt="relay42-logo"
                          className="img"
                          src={require("assets/img/tools/rela.png")}
                        ></img>
                        <img
                          alt="google-tagmanager-logo"
                          className="img"
                          src={require("assets/img/tools/gtm.png")}
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Home;

// <Button className="btn-round" color="info" size="lg" href="/contact">
// Contact me
// </Button>