import React from "react";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

// Google Analytics
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-81897902-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function NavBarSh() {
    ReactGA.pageview(window.location.pathname + window.location.search); 
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          <Nav navbar>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/in/stefanhoogers"
                  target="_blank"
                  id="linkedin-tooltip"
                >
                  <i className="fab fa-linkedin-in"></i>
                  <p className="d-lg-none d-xl-none">Linkedin</p>
                </NavLink>
                <UncontrolledTooltip target="#linkedin-tooltip">
                  Follow me on Linkedin
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink 
                  to="/" tag={Link}> Home
                </NavLink>
              </NavItem>
              { /*
                <NavItem>
                <NavLink 
                  to="/strategy" tag={Link}> Analytics Strategy
                </NavLink>
              </NavItem>
              */}
              <NavItem>
                <NavLink 
                  to="/contact" tag={Link}> Contact
                </NavLink>
              </NavItem>
            </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBarSh;
