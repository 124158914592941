
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import Home from "pages/Home.js";

//import Home_v2 from "pages/Home_v2.js";
import Strategy from "pages/Strategy.js";
import DigitalAnalytics from "pages/DigitalAnalytics.js";

//components
import NavBarSh from "components/Navbars/NavBarSh";
import Contact from "pages/Contact";

ReactDOM.render(
  <HashRouter>
    <NavBarSh />
    <Switch>
      <Switch>
        <Route exact
          path="/"
          component={Home}
          />
          <Route exact
          path="/strategy"
          component={Strategy}
          />
          <Route exact
          path="/digitalanalytics"
          component={DigitalAnalytics}
          />
          <Route exact
          path="/contact"
          component={Contact}
          />
      </Switch>
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);

//  <Route exact
// path="/contact"
// component={Contact}
// />